import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import User from "~/models/User";
import env from "~/env";
import useStores from "~/hooks/useStores";

export interface MatomoTrackingProps {
  children?: React.ReactNode;
}

const MatomoTracking: React.FC<MatomoTrackingProps> = function () {
  const { auth: authStore } = useStores();
  const { pushInstruction } = useMatomo();
  const history = useHistory();
  const location = useLocation();
  const urlPrevRef = useRef<string>();
  // console.log(location);

  let user: User | null | undefined = undefined;
  if (authStore) {
    user = authStore.user;
  }
  const [prevTitle, setPrevTitle] = useState("");

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const documentTitle = location?.state?.title ?? location.pathname;
    const matomoCallback = () => {
      const { pathname: url } = location;
      if (url.includes("/search/") || documentTitle === prevTitle) {
        return;
      }
      // console.log(documentTitle);
      setPrevTitle(documentTitle);
      if (env.MATOMO_SERVER_URL && env.MATOMO_SITE_ID) {
        if (urlPrevRef.current !== undefined) {
          pushInstruction("setReferrerUrl", urlPrevRef.current);
        }
        if (user) {
          pushInstruction("setUserId", `${user.name} ${user.id}`);
        }
        pushInstruction("setCustomUrl", url);
        pushInstruction("setDocumentTitle", documentTitle);
        // pushInstruction("addDownloadExtensions", "docx|xlsx|mp4");
        // pushInstruction("setDownloadClasses", "matomo_download");
        pushInstruction("trackPageView");
        pushInstruction("enableLinkTracking"); // t is recommended to add this line just after the first call to trackPageView or trackEvent
        pushInstruction("trackAllContentImpressions");
      }
      if (url) {
        urlPrevRef.current = url;
      }
    };

    matomoCallback(); // On load (in development mode - strict mode enabled so it run twice)
    const unlisten = history.listen(matomoCallback);
    return () => {
      unlisten();
    };
  }, [history, location, prevTitle, pushInstruction, user]);

  return null;
};

export default observer(MatomoTracking);
